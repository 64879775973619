import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { Button, Icon, Image, Loader, Transition } from 'semantic-ui-react';
import { displayProtectedAppDoc } from '../helper/DisplayProtectedDocument';
import TranslateHelper from "../helper/TranslateHelper";

const CustomModal = (props) => {
    var { translate } = TranslateHelper();
    const [documentUrl, setDocumentUrl] = React.useState<any>();
    const [showLoader, setShowLoader] = React.useState<any>(true);

    function scrollToTop(scrollDuration) {
        var scrollStep = -window.scrollY / (scrollDuration / 15),
            scrollInterval = setInterval(function () {
                if (window.scrollY != 0) {
                    window.scrollBy(0, scrollStep);
                }
                else clearInterval(scrollInterval);
            }, 15);
    }

    React.useEffect(() => {
        //debugger;
        if (props.docUrl != undefined) {
            setDocumentUrl(props.docUrl);
        }
        setTimeout(() => {
            scrollToTop(250);
        }, 1000);
    }, [])

    React.useEffect(() => {
        if (showLoader)
            toast("Lütfen bekleyin...", { type: 'info', theme: 'dark', position: 'top-center', autoClose:2000 });
        else
            toast.dismiss();
    }, [showLoader])

   


    return (
        <>
            <div className="customModalBlur fadeIn"></div>
            <div id="customModal" className={props.isFullScreen ? "customModalContainer customModalContainerFullScreen" : "customModalContainer"}>
                <span onClick={() => { props.setShow(false) }}>
                    <Icon name="close"
                        color="red"
                        className="customModalCloseIcon grow"
                    />
                </span>
                {props.title != undefined
                    &&
                    <span className="customModalHeader">
                        {props.title}
                    </span>
                }
                <Image className="bannerImg" src={displayProtectedAppDoc(props.image,"customModalBannerImg")} id="customModalBannerImg" />
                <div className="customModalContentDiv">
                    {props.body != undefined
                        &&
                        <span className="customModalText fontWeight700">
                            {props.body}
                        </span>
                    }
                    {props.subText != undefined
                        &&
                        <span className="customModalText">
                            {props.subText}
                        </span>
                    }
                    {documentUrl != undefined && showLoader &&
                        <Loader style={{ position: "absolute", left: "45%", top: "30%" }} size={"huge"} active inline='centered' />
                    }
                    {documentUrl != undefined && !isMobile
                        &&
                        <iframe
                            id="customModalIframe"
                            src={props.docUrl}
                            className="widthHeight100"
                           onLoad={() => { setShowLoader(false) }}
                        />
                    }
                    {documentUrl != undefined && isMobile
                        &&
                        <iframe src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + props.docUrl} className="widthHeight100" onLoad={() => { setShowLoader(false) }} />
                    }
                    <p className="customModalBack" onClick={() => { props.setShow(false) }}>
                        <Button  className="dangerRedButton cusrsorPointer">{translate("Kapat")}</Button>
                    </p>
                </div>
            </div>
        </>
    );
}

export default CustomModal;