import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Icon, Modal, Popup, Progress, Rating } from 'semantic-ui-react';
import { DateFormat, DateTimeFormat } from '../helper/DateFormat';
import parse from "html-react-parser";
import { IDataTableData, IDataTableSecretData, IIntervalProps } from '../interfaces/interfaces';

import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { NetworkRequest } from './NetworkRequest';
import { Link } from "react-router-dom";
import { DataHandler } from './DataHelper';
import TranslateHelper from "./TranslateHelper";


import docFile from '../images/FileUploadIcons/new/doc-file.png';
import gifFile from '../images/FileUploadIcons/new/gif-file.png';
import jpegFile from '../images/FileUploadIcons/new/jpeg-file.png';
import pdfFile from '../images/FileUploadIcons/new/pdf-file.png';
import pngFile from '../images/FileUploadIcons/new/png-file.png';
import pptFile from '../images/FileUploadIcons/new/ppt-file.png';
import xlsxFile from '../images/FileUploadIcons/new/xlsx-file.png';
import folderFile from '../images/FileUploadIcons/new/folder.png';
import unauthorizedFile from '../images/FileUploadIcons/new/unauthorized.png';


var _ = require('lodash');
let title = "";

export const renderByItemType = (item, key?, isDateTime?) => {
    try {
        if (typeof item == 'boolean') {
            item = <Icon
                disabled
                name='circle'
                color={item ? 'green' : 'grey'}
            />;
        } else if ((item == null || item == "") && item != 0) {
            item = "-";
        } else if (typeof item == "number") {
            item = item;
        } else if (isDate(item)) {
            if (!isDateTime) {
                item = DateFormat(item);
            } else {
                item = DateTimeFormat(item);
            }

        } else if (typeof item == 'object' && item != null) {
            switch (item.type.toString()) {
                case "progress":
                    item = <Progress style={{ marginTop: "12%" }} percent={item.value} indicating progress />
                    break;
                case "Color":
                    item = <div style={{
                        width: "100%",
                        height: "16px",
                        borderRadius: "8px",
                        background: item.color,
                        border: "solid .5px #ccc"
                    }}></div>
                    break;

                case "rating":
                    item = <><Rating disabled={true} icon='star' defaultRating={item.rateValue} maxRating={5} />
                        <span> {item.rateValue}</span></>
                    break;

                case "systemLogUser":
                    item = <>
                        <Popup
                            content={
                                <div>
                                    <div>Email: {item.email}</div>
                                </div>
                            }
                            trigger={
                                <Link to={"/userEdit/" + item.userId}>
                                    <span style={{ color: "blue", cursor: "pointer" }}>{item.fullname}</span>
                                </Link>
                            }
                        />

                    </>
            }
        } else {// string ve html elemanlar için
            let itemOriginal = item;
            item = item.replace(/<[^>]*>?/gm, '');
            item = item.replace(/\n/g, ' ');
            item = item.replace(/\t/g, ' ');
            item = item.replace(/\&nbsp;/g, ' ');
            item = item.replace('  ', ' ');
            item = item.replace('  ', ' ');
            if (item.length >= 80) {
                item = item.substr(0, 80);
                var lastAndSymbolLoc = item.lastIndexOf('&');
                if (lastAndSymbolLoc > 0) {
                    var lastLength = item.length - lastAndSymbolLoc;
                    if (lastLength < 7) {
                        item = item.substr(0, lastAndSymbolLoc);
                    }
                }
                item += " ...";
                item = { clearText: parse(item), html: parse(itemOriginal) };
            }

        }
        return item;
    } catch (e) {
        ////console.log('renderByItemType error: ', e);
    }
}

const styles = {
    alignRight: {
        textAlign: "right",
        padding: "0 20px"
    },
    alignLeft: {
        textAlign: "left",
        padding: "0 20px"
    },
    alignCenter: {
        textAlign: "center"
    },
    fileName: {
        fontSize: "1.25rem"
    },
    fileContainer: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center ",
        gap: "1rem",
    },
    thumbnailPhoto: {
        maxWidth: "700px", minWidth: "450px"
    },
    thumbnailPhotoIcon: {
        maxWidth: "150px", minWidth: "90px"
    },
    modalPhoto: {
        maxWidth: "1000px", minWidth: "650px",

    }
}

export const styleByItemType = (item, key?) => {
    try {
        item = key == "rank" ? "" :
            typeof item == 'boolean' ?
                styles.alignCenter :

                ((item == null) || (item == "")) ? styles.alignCenter :
                    typeof item == "number" ?
                        styles.alignCenter :
                        (typeof item == "string" && !isDate(item)) ?
                            styles.alignLeft
                            :
                            isNaN(Date.parse(item.toString())) ?
                                styles.alignLeft :
                                styles.alignRight

        return item;
    } catch (e) {
        ////console.log('styleByItemType error: ', e);
        return undefined;
    }
}

export const datableFilterChange = (dataTableModel, isChecked, index, setDataTableModel, setterChecbox) => {
    let dtm = _.cloneDeep(dataTableModel);
    //dtm.filter.filterOption[index].defaultValue = !dtm.filter.filterOption[index].defaultValue;

    dtm.filter.filterOption[index].defaultValue = isChecked;

    if (dtm.filter.filterOption[index].desc == "Aktif") {
        dtm.filter.filterOption.filter(x => x.key == "isDeleted")[0].defaultValue = !isChecked;
    } else if (dtm.filter.filterOption[index].desc == "Silinen") {
        dtm.filter.filterOption.filter(x => x.key == "isActive")[0].defaultValue = !isChecked;
    }

    setDataTableModel(dtm);

    setterChecbox(isChecked);
}

const isDate = (value) => {
    const _regExp = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
    return _regExp.test(value);
}

export const escapeFromTurkishLetters = (string) => {
    var letters = {
        "i": "İ",
        "İ": "i",
        "I": "ı",
        "Ş": "ş",
        "Ğ": "g",
        "Ü": "u",
        "Ö": "o",
        "Ç": "c",
        "ç": "c",
        "ğ": "g",
        "ı": "i",
        "ö": "o",
        "ş": "s",
        "ü": "u"
    };

    string = string.replace(/(([İIŞĞÜÇÖçğıöüş]))/g, function (letter) {
        return letters[letter];
    });

    return string.toLowerCase();
};

export const deleteProportyFromObjectArray = (array, propertyArray) => {
    ////console.log("array");
    ////console.log(array);
    let rt;
    propertyArray.filter((elem) => {
        delete array[elem];
    });
    rt = array;
    return rt;
}

export const prepareDataForTable = (data: Array<IDataTableSecretData>, deletedProperty) => {

    /* format

       {
            showData: [
                <Checkbox />,
                "deneme",
                "70",
                "test açıklaması",
                "01.01.1997",
                "50"
            ],

            secretData: [{
                id: "3800b51d-4f60-4b18-a7bd-0b659740b5c1"
            }]

        }

     */

    let dataObject: Array<IDataTableData> = [];

    data.map(element => {
        dataObject.push({
            secretData: [
                //{ id: element.id },
                //{ isDeleted: element.isDeleted }
                {
                    id: element.id,
                    isDeleted: element.isDeleted,
                    isActive: element.isActive,
                    isSelected: element.isSelected != undefined ? element.isSelected : false
                }
            ],
            showData: [deleteProportyFromObjectArray(element, deletedProperty)]
        });
    });

    ////console.log("dataObject: ", dataObject);
    return dataObject;
}

export const checkFieldIsFilled = (field) => {
    let rt = field != undefined && field != null && field != "";
    return rt;
}

export const checkFieldsIsFilled = (...field) => {
    ////console.log("checkFieldsIsFilled field: ", field);
    let rt;
    for (var i = 0; i < field.length; i++) {
        rt = checkFieldIsFilled(field[i]);
        if (rt == false)
            break;
    }

    return rt;
}

export const sessionControlForLogout = () => {

    if (DataHandler.getUserDataWithKey("userRole") == undefined
        && window.location.pathname !== "/login"
        && window.location.pathname !== "/resetPassword"
        && window.location.pathname !== "/ldapLogin"
        && window.location.pathname !== "/aboutUs"
        && window.location.pathname !== "/newTenant"
        && window.location.pathname !== "/home"
        && window.location.pathname !== "/packets"
        && window.location.pathname !== "/homeLogin"
        && window.location.pathname !== "/ldapLogin"
        && window.location.pathname !== "/aelogin"
        && window.location.pathname !== "/hblogin"
        && window.location.pathname !== "/scormTest"
        && window.location.pathname !== "/"
        && window.location.pathname !== "/privacyPolicy"
        && window.location.pathname !== "/notAuthorized"
        && window.location.pathname !== "/NotFoundPage"
        && window.location.pathname !== "/homev2"
        && window.location.pathname !== "/partner"
        && window.location.pathname !== "/customers"
        && window.location.pathname !== "/requestdemo"
        && window.location.pathname !== "/pricing"
        && window.location.pathname !== "/generateSecret"
    ) {
        window.location.href = "/homeLogin";
    }
}

export const digitallicaConfirm = (msg, onConfirm?, onReject?, intervalProps?: IIntervalProps, confirmLabel?, rejectLabel?) => {
    let interval;
    confirmAlert({
        title: 'Devam Etmek İçin Onaylayın',
        message: msg,
        onClickOutside: () => {
            onReject();
        },
        onKeypressEscape: () => {
            onReject();
        },
        closeOnClickOutside: false,
        buttons: [
            {
                style: {borderRadius:"50%"},
                label: confirmLabel == undefined ? 'Evet' : confirmLabel,
                onClick: () => {
                    clearInterval(interval);
                    onConfirm();
                }
            },
            {
                label: rejectLabel == undefined ? 'Hayır' : rejectLabel,
                onClick: () => {
                    clearInterval(interval);
                    onReject();
                }
            }
        ]
    })
    if (intervalProps != undefined) {
        let seconds = intervalProps.seconds;
        interval = setInterval(() => {
            if (intervalProps.rejectOnTimeEnd) {
                var elem = document.getElementsByClassName('react-confirm-alert-button-group')[0].children[1];
                if (elem != undefined) {
                    elem.innerHTML = rejectLabel == undefined ? 'Hayır' : rejectLabel + " " + seconds;
                }
            } else {
                var elem = document.getElementsByClassName('react-confirm-alert-button-group')[0].children[0];
                if (elem != undefined) {
                    elem.innerHTML = confirmLabel == undefined ? 'Evet' : confirmLabel + " " + seconds;
                }
            }

            if (seconds == 0) {
                toast(intervalProps.onTimeEndMessage, {
                    type: 'warning',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2000
                });
                clearInterval(interval);
                setTimeout(() => {
                    if (intervalProps.rejectOnTimeEnd) {
                        onReject();
                    } else {
                        onConfirm();
                    }
                }, 500)
            }
            seconds -= 1;
        }, 1000)
    }


    return { result: true };
}

export const base64ToByteArray = (base64) => {
    let binaryString = window.atob(base64);
    let len = binaryString.length;

    let bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

export const downloadFile = (fileContents, fileName) => {
    let bytes = base64ToByteArray(fileContents);

    let blob = new Blob([bytes], { type: "application/octet-stream" });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    link.download = fileName + " - " + DateFormat(new Date(), true) + ".xlsx";
    link.click();
}

export const giveMeFileNameWithoutPath = (fileName) => {
    if (!fileName)
        return "";
    return fileName.indexOf(".") == -1 ? fileName : fileName.slice(0, fileName.lastIndexOf("."));
}

export const downloadFileWithEx = (fileContents, fileName, extension) => {

    let bytes = base64ToByteArray(fileContents);

    let blob = new Blob([bytes], { type: "application/octet-stream" });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    link.download = giveMeFileNameWithoutPath(fileName) + "." + extension;
    link.click();
}

//export const showViaPreviewOnDom = (extension: any) => {
//    if (extension == "pdf" || extension == "png" || extension == "jpg" || extension == "jpeg" || extension == "gif") {
//        Base64Renderer()
//    }

//    return false;
//}

export const getBase64Type = (base64Content) => {
 
    if (base64Content.startsWith('JVBERi0')) {
        return 'pdf';
    } else if (base64Content.startsWith('/9j/') || base64Content.startsWith('iVBORw0KGgo')) {
        return 'image';
    }
    return 'unknown';
};

const renderBase64Content = (base64Content) => {
  
    const type = getBase64Type(base64Content);

    if (type === 'image') {
        return <img width="100%" height="auto" src={`data:image/jpeg;base64,${base64Content}`} alt="base64-image" />;
    } else if (type === 'pdf') {
        const pdfData = `data:application/pdf;base64,${base64Content}`;
        return <iframe src={pdfData} width="100%" style={{ height:"100vh" }} title="PDF Viewer"></iframe>;

    }
    return <></>;
};

export const Base64Renderer = (base64Content) => {
    console.log(base64Content);
    return (

        <div style={{ width:"100%" }}>{renderBase64Content(base64Content)}</div>
        
    );
};

export const tabTitle = (title: string) => {
    return document.title = (title == null || title == "") ? "Buddy | Digitallica" : title;
}

export const toCapitalize = (text) => {
    if (text == undefined || text == "" || text == null) return "";
    return text[0].toUpperCase() + text.substring(1);
}

//force redirect login page when on mobile device..
export const checkLoginTimestamp = () => {
    let cookie = DataHandler.getUserDataWithKey("email");
    let loginTimestampCookie = DataHandler.getUserDataWithKey("loginTimestamp");
    if (cookie == undefined) {
        DataHandler.logOutUser(true);
        if (isMobile && window.location.pathname != '/homeLogin' && window.location.pathname !== "/ldapLogin") {
            //alert("checkLoginTimestamp");
            //debugger;

            //window.location.href = "/homeLogin";
        }
    }
    if (loginTimestampCookie != undefined && moment(new Date(Number(loginTimestampCookie))).add(70, 'm').diff(moment()) < 0) {
        DataHandler.logOutUser(true);

        if (isMobile && window.location.pathname != '/homeLogin' && window.location.pathname !== "/ldapLogin") {
            //alert("checkLoginTimestamp2");
            //debugger;

            // window.location.href = "/homeLogin";
        } else if (window.location.pathname != '/' && !isMobile) {
            //debugger;
            // window.location.href = "/";
        }
    }
}

export const scrollToElem = (id, latency) => {
    var elem1: any = document.getElementsByTagName("body")[0].getBoundingClientRect();
    var elem2: any = document.getElementById(id)?.getBoundingClientRect();
    document.getElementsByClassName('moduleDetailColumnGrid')[0] != undefined && document.getElementsByClassName('moduleDetailColumnGrid')[0].scrollTo({
        top: (elem1 != undefined || elem1 != null ? elem1.top : 0) -
            (elem2 != undefined || elem2 != undefined ? elem2.height : 0),
        behavior: "smooth"
    })
}

export const getPassedTime = (time: string) => {
    let today = new Date(time);
    let todayTime = new Date().getTime() - today.getTime();
    let diff = Math.ceil(todayTime / 60000);
    if (diff >= 60) {
        let h = Math.floor(diff / 60);
        let m = Math.ceil(diff % 60);

        if (h >= 24) {
            let d = Math.floor(h / 24);
            h = (h % 24);
            return (d + " " + TranslateHelper().translate("gün") + " " + (h != 0 ? h + " " + TranslateHelper().translate("saat önce") : TranslateHelper().translate("önce")));
        } else {
            return (h + " " + TranslateHelper().translate("saat") + " " + (m != 0 ? m + " " + TranslateHelper().translate("dakika önce") : +" " + TranslateHelper().translate("önce")))
        }

    }
    return ((diff) + " " + TranslateHelper().translate("dakika önce"));
}

export const scrollToTop = () => {
    //Appointment of Scroll at the top when the content changes
    document.getElementById("appDiv")?.scrollTo({ top: 0, behavior: "smooth" });
}

export const logoutApi = () => {
    NetworkRequest('/Authentication/Logout', { id: DataHandler.getUserDataWithKey("userId") })
}

export const redirectByUserRole = (userRole?: string) => {
    if (userRole == undefined) {

        //alert("redirectByUserRole");
        //window.location.href = "/#/homeLogin";
        //window.location.reload();

        window.location.href = "/";

    } else {

        window.location.href = "/";
    }
}

export const isNullOrEmpty = (data?: string) => {
    data = data?.trim();
    return data == undefined || data == "" || data == null;
}

//".xlsx,.xls,.pptx,.ppt,.doc,.docx,.pdf,.gif,.jpeg,.jpg,.png,.tiff,.srt,.vtt",
const getFileExtension = (fileUrl) => {
    var ext = fileUrl.substring(fileUrl.lastIndexOf('.') + 1, fileUrl.length) || fileUrl;
    return ext;
}

const icons = [
    "/Documents/FileUploadIcons/new/doc-file.png",
    "/Documents/FileUploadIcons/new/gif-file.png",
    "/Documents/FileUploadIcons/new/jpeg-file.png",
    "/Documents/FileUploadIcons/new/pdf-file.png",
    "/Documents/FileUploadIcons/new/png-file.png",
    "/Documents/FileUploadIcons/new/ppt-file.png",
    "/Documents/FileUploadIcons/new/xlsx-file.png",
    "/Documents/FileUploadIcons/new/folder.png",
];

export const giveMePreview = (fileUrl, docUrl, isSaved, photoThumbnail, setPhotoThumbnail, fileName?, taskDocUrl?, mimeType?) => {


    var path = getFileExtension(fileUrl);
    if (mimeType != undefined) {
        path = mimeType;
    }
    var rt = { src: "", type: "img", isIcon: false };
    if (path.indexOf("png") != -1) {
        rt.src = isSaved ? fileUrl : taskDocUrl;
    } else if (path.indexOf("jpeg") != -1 || path.indexOf("jpg") != -1) {
        rt.src = isSaved ? fileUrl : taskDocUrl;
    } else if (path.indexOf("gif") != -1) {
        rt.src = isSaved ? fileUrl : taskDocUrl;
    } else if (path.indexOf("ppt") != -1 || path.indexOf("pptx") != -1) {
        rt.src = pptFile;
        rt.isIcon = true;
    } else if (path.indexOf("pdf") != -1) {
        rt.src = isSaved ? fileUrl : pdfFile;
        rt.type = "iframe";
    } else if (path.indexOf("xls") != -1 || path.indexOf("xlsx") != -1) {
        rt.src = xlsxFile;
        rt.isIcon = true;
    } else if (path.indexOf("doc") != -1 || path.indexOf("docx") != -1) {
        rt.src = docFile;
        rt.type = "doc";
        rt.isIcon = true;
    } else {
        rt.src = icons[7];
        rt.isIcon = true;
    }
    /*    debugger;*/
    if (rt.type == "img") {
        return <div className="containerFileUpload">
            {isSaved ?
                <div>
                    {!rt.isIcon ?
                        <div onClick={() => {
                            setPhotoThumbnail(true)
                        }} style={styles.fileContainer}>
                            <img
                                className="imgStyle"
                                style={styles.thumbnailPhoto}
                                src={rt.src}
                            />
                        </div> :
                        <a href={fileUrl} target="_blank" download>
                            <img
                                className="imgStyle"
                                style={styles.thumbnailPhotoIcon}
                                src={rt.src}
                            />
                        </a>
                    }

                </div>
                :

                <img
                    className="imgStyle"
                    style={{
                        opacity: "0.75",
                        cursor: "unset", ...!rt.isIcon ? styles.thumbnailPhoto : styles.thumbnailPhotoIcon
                    }}
                    src={rt.src}
                />

            }
            <Modal
                onClose={() => setPhotoThumbnail(false)}
                onOpen={() => setPhotoThumbnail(true)}
                open={photoThumbnail}
                centered={false}
                size='fullscreen'
            >
                <Modal.Actions>
                    <Button
                        className={"dangerRedButton"}
                        onClick={() => setPhotoThumbnail(false)}>
                        {"Kapat"}
                    </Button>
                </Modal.Actions>
                <Modal.Content style={{ display: "flex", flexDirection: "column" as "column", alignItems: "center" }}
                    image>

                    <img style={styles.modalPhoto} src={rt.src} />

                </Modal.Content>
            </Modal>
        </div>
    } else {
        return <div className="contentAddPreviewIframeContainer">
            <iframe className={"contentAddPreviewIframe"} src={isSaved ? rt.src : taskDocUrl} height="500"
                style={{ width: "90%" }} />
        </div>
    }
}

export const giveMePreviewWithBlob = (mimeType, fileUrl, docUrl, isSaved, photoThumbnail, setPhotoThumbnail, fileName?, taskDocUrl?) => {


    if (mimeType == undefined) {
        return;
        /*mimeType = 'application/pdf';*/
    }
    let blob = base64toBlob(fileUrl, mimeType);
    let url = URL.createObjectURL(blob);
    return giveMePreview(url, docUrl, isSaved, photoThumbnail, setPhotoThumbnail, fileName, taskDocUrl, mimeType);
}

export const giveMeIconForDocType = (path) => {
    /*    debugger;*/

    if (path == undefined) {
        return pngFile;
    }

    if (path.src == undefined)
        path = path.slice(path.lastIndexOf(".") + 1).toLowerCase()
    else
        path = path.src.slice(path.src.lastIndexOf(".") + 1).toLowerCase()

    switch (path) {
        case "png":
            return pngFile;
        case "jpeg":
        case "jpg":
            return jpegFile;
        case "ppt":
        case "pptx":
            return pptFile;
        case "pdf":
            return pdfFile;
        case "xls":
        case "xlsx":
            return xlsxFile;
        case "doc":
        case "docx":
            return docFile;
        case "gif":
            return gifFile;
        default:
            return pngFile;

    }
}

export const getRandomIntInclusive = () => {
    let min = 0;
    let max = 99999999;
    const randomBuffer = new Uint32Array(1);

    window.crypto.getRandomValues(randomBuffer);

    let randomNumber = randomBuffer[0] / (0xffffffff + 1);

    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(randomNumber * (max - min + 1)) + min;
}

export const getRandomIntInclusive01 = () => {
    let min = 0;
    let max = 1;
    const randomBuffer = new Uint32Array(1);

    window.crypto.getRandomValues(randomBuffer);

    let randomNumber = randomBuffer[0] / (0xffffffff + 1);

    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(randomNumber * (max - min + 1)) + min;
}

export const base64toBlob = (data: string, mimeType: string): Blob | MediaSource => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    //const base64WithoutPrefix = data.substr('data:application/pdf;base64 ,'.length);

    // if(mimeType == null)
    //     return new Blob();

    const bytes = atob(data);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    //return new Blob([out], { type: 'application/pdf' });
    return new Blob([out], { type: mimeType });
};

export const isLandscapeTablet = (): boolean => {
    return (window.innerWidth >= 800 && window.innerWidth <= 1300)
}

// export const useInstanceLoader = (siteKey: string) => {
//     const helperInstance = useRef(null);
//
//     useEffect(() => {
//         // @ts-ignore
//         if (!window.grecaptcha) {
//             const script = document.createElement('script');
//             script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
//             script.async = true;
//             script.defer = true;
//             script.onload = () => {
//                 // @ts-ignore
//                 helperInstance.current = window.grecaptcha;
//             };
//             document.body.appendChild(script);
//         } else {
//             // @ts-ignore
//             helperInstance.current = window.grecaptcha;
//         }
//
//         return () => {
//             if (helperInstance.current) {
//                 // @ts-ignore
//                 helperInstance.current.reset();
//             }
//         };
//     }, []);
//
//     return helperInstance.current;
// };


export const isVideoFile = (file) => {
    const videoMimeTypes = [
        'video/mp4',
        'video/webm',
        'video/ogg',
        'video/quicktime', // .mov files
        'video/x-msvideo', // .avi files
        'video/x-matroska' // .mkv files
    ];

    return videoMimeTypes.includes(file.type);
};


export const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.setAttribute("crossOrigin", "anonymous");
        image.src = url;
    });

export function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation);

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
}

export function extractIframe(htmlString): string {
    // HTML entitilerini decode eden bir div oluşturuyoruz
    const parser = new DOMParser();
    const decodedString = htmlString.replace(/&lt;/g, '<').replace(/&gt;/g, '>');

    // HTML stringini parse et
    const doc = parser.parseFromString(decodedString, 'text/html');

    // Embed kodları için olası elementleri seç
    const iframe = doc.querySelector('iframe');
    const object = doc.querySelector('object');
    const embed = doc.querySelector('embed');
    const video = doc.querySelector('video');

    // Embed kodları arasında bulunan ilk öğeyi döndür
    if (iframe) {
        return iframe.outerHTML;
    } else if (object) {
        return object.outerHTML;
    } else if (embed) {
        return embed.outerHTML;
    } else if (video) {
        return video.outerHTML;
    }

 

    // Hiçbir embed öğesi bulunmadıysa null döndür
    return "";
}

export function extractAllElements(htmlString: string): string {
    // HTML entitilerini decode etmek için bir div kullanıyoruz
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"').replace(/&#39;/g, "'").replace(/&amp;/g, '&');

    // Tüm elementlerin outerHTML'lerini birleştir
    let combinedHTML = "";
    Array.from(tempDiv.childNodes).forEach((node: ChildNode) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
            const element = node as HTMLElement;
            combinedHTML += processElement(element);
        } else if (node.nodeType === Node.TEXT_NODE) {
            combinedHTML += node.textContent; // Metin node'ları olduğu gibi ekle
        }
    });

    // Birleştirilmiş HTML stringini döndür
    console.log(combinedHTML);
    return combinedHTML;
}

function processElement(element: HTMLElement): string {
    let elementHTML = "";
    if (element.tagName.toLowerCase() === 'p') {
        // <p> etiketi içindeki çocuk öğeleri yönet
        let containsEmbed = false;
        let innerHTML = "";
        Array.from(element.childNodes).forEach((childNode: ChildNode) => {
            if (childNode.nodeType === Node.ELEMENT_NODE) {
                const childElement = childNode as HTMLElement;
                if (['iframe', 'object', 'embed', 'video'].includes(childElement.tagName.toLowerCase())) {
                    containsEmbed = true;
                    innerHTML += childElement.outerHTML; // Embed elementleri olduğu gibi ekle
                } else {
                    innerHTML += childElement.outerHTML; // Diğer elementleri olduğu gibi ekle
                }
            } else if (childNode.nodeType === Node.TEXT_NODE) {
                innerHTML += childNode.textContent; // Metin node'ları olduğu gibi ekle
            }
        });
        if (containsEmbed) {
            elementHTML += innerHTML; // Eğer <p> etiketi embed içeriyorsa, <p> etiketi olmadan ekle
        } else {
            elementHTML += `<p>${innerHTML}</p>`; // Aksi takdirde <p> etiketiyle birlikte ekle
        }
    } else {
        elementHTML = element.outerHTML;
    }
    return elementHTML;
}

export const getQuestionContentForRender = (body) => {
    var value = body?.embedCode || body?.EmbedCode;
    return typeof (body) == "object" ? value : body
}

export async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
) {
    const image: any = await createImage(imageSrc);
    const canvas: any = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d");

    if (!ctx) {
        return null;
    }

    const rotRad = getRadianAngle(rotation);

    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    ctx.drawImage(image, 0, 0);

    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    );


    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(data, 0, 0);

    const base64Canvas = canvas.toDataURL("image/jpeg").split(';base64,')[1];

    return base64Canvas;

    //return new Promise((resolve, reject) => {
    //    canvas.toBlob((file) => {
    //        resolve(URL.createObjectURL(file));
    //    }, "image/jpeg");
    //});
}
